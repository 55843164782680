var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div data-o-grid-colspan=\"12 M6 L4\" class=\"column myft-section-content__column\">\n"
    + ((stack1 = container.invokePartial(require("../../bower_components/n-topic-card/templates/concept.html"),depth0,{"name":"n-topic-card/templates/concept","hash":{"showPrioritiseButton":((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"flags"))) && lookupProperty(stack1,"myftPrioritiseTopicsOnHomepage")),"cacheablePersonalisedUrl":true,"setFollowButtonStateToSelected":(depth0 != null ? lookupProperty(depth0,"isFollowing") : depth0),"items":(depth0 != null ? lookupProperty(depth0,"latestContent") : depth0),"conceptId":(depth0 != null ? lookupProperty(depth0,"id") : depth0),"flags":((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"flags"))},"data":data,"indent":"\t\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "					</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "			<p class=\"myft-section-tail-action\">\n				<a\n				class=\"myft-section-tail-action__link\"\n					data-trackable=\"industry recommendations\"\n					href=\"/myft/explore#industry\">\n					Find topics relevant to your industry\n				</a>\n			</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section data-trackable=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"o-grid-row section--myft section--myft-teasers\">\n	<div data-o-grid-colspan=\"12\">\n		<h2 class=\"o-teaser-collection__heading\">\n			<a href=\"/myft\">Recent stories from myFT</a>\n		</h2>\n	</div>\n	<div id=\"myft-section-content\" data-o-grid-colspan=\"12\" class=\"section__column section__column--content\">\n		<div class=\"section__column__inner\">\n			<div class=\"o-grid-row\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"content") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":20,"column":13}}})) != null ? stack1 : "")
    + "			</div>\n		</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"flags"))) && lookupProperty(stack1,"myftIndustryRecs")),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":2},"end":{"line":32,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n</section>\n";
},"usePartial":true,"useData":true});