import { $, $$ } from 'n-ui-foundations';
import { ui as myFtUi } from 'n-myft-ui/myft';
import { toggleState as toggleMyFtState } from 'n-myft-ui/myft-common';
import templates from './templates';

import Video from 'o-video';
import Share from 'o-share';

const VIDEO_PLAYER_WRAPPER_SELECTOR = '.video-section__column--player';
const VIDEO_PLAYER_SELECTOR = '.video-section__player';
const PLAYLIST_ITEM_SELECTOR = '.video-section__item';
const LINKS_IN_PLAYLIST_ITEM_SELECTOR = 'a:not(.o-teaser__tag)';
const TEASER_HEADING_SELECTOR = '.js-teaser-heading-link';
const SHARE_WRAPPER_SELECTOR = '.video-section__share-wrapper';
const SHARE_SELECTOR = '.video-section__share';
const SAVE_FOR_LATER_SELECTOR = '.n-myft-ui--save';
const SAVE_FOR_LATER_BUTTON_SELECTOR = '.n-myft-ui__button';
const UP_NEXT_SELECTOR = '.js-video-up-next';
const TEASER_CONTENT_SELECTORS = [
	'.o-teaser__meta'
];

const PLAYLIST_ITEM_PLAYING_CLASS = 'video-section__item--now-playing';

const state = {
	queue: [],
	player: null,
	playlist: null,
	sharer: null,
	autoplay: null,
	playsFullScreen: null,
	elements: {
		videoPlayer: null,
		playlistItems: null,
		shareWrapper: null,
		playerWrapper: null,
		saveForLaterForm: null,
		saveForLaterButton: null,
		upNext: null
	}
};

export function init (flags) {
	state.elements.videoPlayer = $(VIDEO_PLAYER_SELECTOR);
	state.elements.playlistItems = $$(PLAYLIST_ITEM_SELECTOR);

	if (!state.elements.videoPlayer || !state.elements.playlistItems) {
		return;
	}

	getElements();
	createVideoQueue();
	addListeners();
	run(flags);

	if (flags.get('ads') && flags.get('videoPlayerAdvertising')) {
		//TODO: Stop using oTracking to pass ad events
		//https://github.com/Financial-Times/o-video/blob/v2.3.18/src/js/ads.js#L303
		document.body.addEventListener('oTracking.event', e => advertEventHandler(e));
	}
}

function run (flags) {
	state.player = new Video(state.elements.videoPlayer, {
		advertising: flags.get('ads') && flags.get('videoPlayerAdvertising'),
		playsinline: true,
		placeholder: true,
		optimumwidth: 600,
		placeholderInfo: [],
		systemcode: 'next-front-page'
	});

	state.playlist = new Video.Playlist({
		player: state.player,
		queue: state.queue.map(({ id }) => id),
		autoplay: false
	});

	state.sharer = new Share($(SHARE_SELECTOR));

	state.elements.videoPlayer.addEventListener('ended', onVideoEnd, true);
	state.elements.videoPlayer.addEventListener('playing', onVideoPlaying, true);

	state.elements.playlistItems[0].classList.add('video-section__item--now-playing');
}

function getElements () {
	state.elements.shareWrapper = $(SHARE_WRAPPER_SELECTOR);
	state.elements.playerWrapper = $(VIDEO_PLAYER_WRAPPER_SELECTOR);
	state.elements.saveForLaterForm = $(SAVE_FOR_LATER_SELECTOR, state.elements.playerWrapper);
	state.elements.saveForLaterButton = $(SAVE_FOR_LATER_BUTTON_SELECTOR, state.elements.saveForLaterForm);
	state.elements.upNext = $(UP_NEXT_SELECTOR);
}

function createVideoQueue () {
	state.queue = state.elements.playlistItems.map(getVideo);
}

function addListeners () {
	state.elements.videoPlayer.addEventListener('playing', onVideoPlay, true);

	state.elements.playlistItems.forEach($playlistItem => {
		const $itemLinks = $$(LINKS_IN_PLAYLIST_ITEM_SELECTOR, $playlistItem);

		$itemLinks.forEach($itemLink => {
			$itemLink.addEventListener('click', e => {
				state.autoplay && clearTimeout(state.autoplay);
				onPlaylistItemClick($playlistItem);

				e.preventDefault();
			});
		});
	});
}

function onVideoPlay () {
	const playlistItem = getPlaylistItem(state.playlist.currentIndex);

	if (playlistItem) {
		updateNowPlaying(playlistItem);
	}
}

function onPlaylistItemClick ($playlistItem) {
	// Don't change video if the ads are playing
	if (state.player.videoAds.adsManager && state.player.videoAds.adsManager.getCurrentAd()) {
		return;
	}

	const video = getVideo($playlistItem);

	state.elements.upNext.innerHTML = '';

	updateNowPlaying($playlistItem);
	updateTeaserAttributes($playlistItem);
	updateAndPlayVideo(video);
	updateSaveForLater(video);
	updateShareComponent(video);
}

function getVideo ($playlistItem) {
	const playlistItemHeading = $(TEASER_HEADING_SELECTOR, $playlistItem);
	const playlistItemDuration = $('.o-teaser__tag-suffix');

	const url = playlistItemHeading.getAttribute('href');
	const id = url.replace('/video/', '');
	const title = playlistItemHeading.innerText;
	const duration = playlistItemDuration.innerText;

	return { id, title, url, duration };
}

function updateNowPlaying ($playlistItem) {
	state.elements.playlistItems.forEach(item => {
		item.classList.remove(PLAYLIST_ITEM_PLAYING_CLASS);
	});

	$playlistItem.classList.add(PLAYLIST_ITEM_PLAYING_CLASS);
}

function updateTeaserAttributes ($playlistItem) {
	TEASER_CONTENT_SELECTORS.forEach(attr => {
		const originalElement = $(attr, state.elements.playerWrapper);
		const newElement = $(attr, $playlistItem).cloneNode(true);

		originalElement.parentNode.replaceChild(newElement, originalElement);
	});
}

function updateAndPlayVideo (video) {
	const index = state.queue.findIndex(({ id }) => id === video.id);

	// calling .goto() would autoplay which messes ads up on iOS.
	// so on first click, don't autoplay...
	state.playlist.currentIndex = index;

	return state.player.update({ id: video.id })
		.then(() => {
			state.player.play();
		});
}

function replaceShareHTMLWith (newHTML) {
	if (state.sharer && state.sharer.destroy) {
		state.sharer.destroy();
		state.sharer = null;
	}

	state.elements.shareWrapper.innerHTML = newHTML;
}

function updateShareComponent (options) {
	if (options && options.url && options.title) {
		replaceShareHTMLWith(templates.share(options));
		state.sharer = new Share($('[data-o-component=o-share]', state.elements.shareWrapper));
		// add data-trackable elements
		// NOTE: ideally this would be doable via o-share's api
		$$('.o-share__icon', state.elements.shareWrapper).forEach(shareItemEl => {
			// get the share name
			const [, shareName] = /o-share__icon--([^\s]*)/.exec(shareItemEl.className);
			shareItemEl.querySelector('a')
				.setAttribute('data-trackable', shareName);
		});

	} else {
		// Wipe out the oShare instance if there’s not enough info for it to work
		replaceShareHTMLWith('');
		console.error('Couldn’t find a title/URL for the current video teaser'); //eslint-disable-line no-console
	}
}

function updateSaveForLater (options) {
	if (options && options.id && options.title) {
		const isPressed = state.elements.saveForLaterButton.getAttribute('aria-pressed') === 'true';
		const btnTitle = `Save ${options.title} to myFT for later`;

		if (isPressed) {
			toggleMyFtState(state.elements.saveForLaterButton);
		}

		state.elements.saveForLaterForm.setAttribute('data-content-id', options.id);

		state.elements.saveForLaterButton.setAttribute('data-content-id', options.id);
		state.elements.saveForLaterButton.setAttribute('aria-label', btnTitle);
		state.elements.saveForLaterButton.setAttribute('title', btnTitle);

		myFtUi.updateUi(state.elements.playerWrapper);
	} else {
		console.error('Couldn’t find a title/URL for the current video teaser'); //eslint-disable-line no-console
	}
}

function onVideoEnd () {
	// ads can trigger this event so check if there was an ad that just played
	if (state.player.videoAds.adsManager && state.player.videoAds.adsManager.getCurrentAd()) {
		return;
	}

	// bail if we were using an out-of-browser player
	if (state.playsFullScreen) {
		return;
	}

	const index = state.playlist.currentIndex + 1;

	// bail if we've run out of videos to play
	if (index >= state.queue.length) {
		return;
	}

	const playlistItem = getPlaylistItem(index);

	// we have different numbers of playlist items on differing screen widths,
	// ensure the next one is visible before playing it.
	if (!playlistItem) {
		return;
	}

	autoplay(playlistItem);
}

function getPlaylistItem (index) {
	const item = state.elements.playlistItems[index];

	// is the item visible?
	// different screen widths show different numbers of videos
	if (item && item.offsetParent !== null) {
		return item;
	} else {
		return;
	}
}

function autoplay (playlistItem) {
	// add 'up next'
	const nextVideo = getVideo(playlistItem);
	state.elements.upNext.innerHTML = templates.upNext(nextVideo);
	$('a', state.elements.upNext).addEventListener('click', (e) => {
		onPlaylistItemClick(playlistItem);
		e.preventDefault();
	});

	// event listener in case the user scrubs/plays during the timeout
	state.elements.videoPlayer.addEventListener('playing', playDuringAutoplayHandler, true);

	// simulate clicking on the next playlist item
	state.autoplay = setTimeout(() => {
		state.elements.videoPlayer.removeEventListener('playing', playDuringAutoplayHandler, true);
		onPlaylistItemClick(playlistItem);
	}, 5000);
}

function playDuringAutoplayHandler () {
	clearTimeout(state.autoplay);
	state.elements.upNext.innerHTML = '';
}

function advertEventHandler (e) {
	if (e.detail && e.detail.action === 'adSkip') {
		const playerVidEl = state.player.videoEl;
		if (!playerVidEl) { return; }

		// Fixes issue with keyboard navigators being shoved back to the top of the page after skipping ads
		playerVidEl.setAttribute('tabindex', 0);
		// Very much relying on there not being multiple video players on the front page
		playerVidEl.focus();
	}
}

function isCurrentlyFullScreen () {
	return document.fullscreenElement ||
		document.webkitFullscreenElement ||
		document.mozFullScreenElement ||
		document.msFullscreenElement ||
		// iOS Safari implements this property on the video element
		state.player.videoEl.webkitDisplayingFullscreen;
}

// Called when the video starts playing
// If it begins in fullscreen, they are probably on an iphone
function onVideoPlaying (e) {
	state.playsFullScreen = !!isCurrentlyFullScreen(e.target);
	state.elements.videoPlayer.removeEventListener('playing', onVideoPlaying, true);
}
