function createCCPALink (consentPageUrl) {
	// Get a reference to the node we want to insert our link before
	const termsLink = document.querySelector(
		'#site-footer [href=\'http://help.ft.com/help/legal-privacy/privacy/\']'
	);

	// Clone the node to keep consistent with structure and style
	const ccpaLink = termsLink.cloneNode(true);
	const ccpaLabel = 'Do Not Sell My Info';

	// Customise the attributes
	ccpaLink.href = consentPageUrl;
	if (ccpaLink.dataset) {
		ccpaLink.dataset.trackable = ccpaLabel;
	}
	ccpaLink.textContent = ccpaLabel;

	// Prepend our new link
	const parent = termsLink.parentNode;
	parent.insertBefore(ccpaLink, termsLink);
}

module.exports = {
	createCCPALink
};
