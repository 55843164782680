const slimQuery = query => encodeURIComponent(query.replace(/\s+/g, ' '));

const basicConcept = `
	fragment BasicConcept on Concept {
		type: __typename
		id
		prefLabel
		name: prefLabel
		url
		directType
		relativeUrl
	}
`;
const basicFragment = `
fragment Basic on Concept {
	type: __typename
	id
	prefLabel
	name: prefLabel
	url
	directType
	latestContent(limit: 5) {
		id
		publishedDate
		url
		title
		isPremium
		... on Article {
			isPodcast
		}
		mainImage {
			url
		}
	}
}
`;


const popular = slimQuery(`
	${basicFragment}

	query {
		popularConcepts(limit: 6, excludeTypes:["http://www.ft.com/ontology/Genre","http://www.ft.com/ontology/Section","http://www.ft.com/ontology/Location"]) {
			... Basic
		}
	}
`);

const topics = slimQuery(`
	${basicConcept}

	query MyFT($uuid: String!) {
		popularConcepts(limit: 20, excludeTypes:["http://www.ft.com/ontology/Genre","http://www.ft.com/ontology/Section","http://www.ft.com/ontology/Location"]) {
			... BasicConcept
		},
		user(uuid: $uuid) {
			conceptsFromReadingHistory(limit: 20) {
				id
				prefLabel
				directType
			}
		}
	}
`);

const anonTopics = slimQuery(`
	${basicConcept}

	query {
		popularConcepts(limit: 20, excludeTypes:["http://www.ft.com/ontology/Genre","http://www.ft.com/ontology/Section","http://www.ft.com/ontology/Location"]) {
			... BasicConcept
		}
	}
`);

const readingHistory = slimQuery(`
	query MyFT($uuid: String!) {
		user(uuid: $uuid) {
			conceptsFromReadingHistory(limit: 20) {
				id
				prefLabel
				directType
			}
		}
	}
`);

export { popular, topics, anonTopics, readingHistory };
