const getConceptCardTrackingInfo = require('./get-concept-card-tracking-info');

module.exports = concepts => {
	document.body.dispatchEvent(new CustomEvent('oTracking.event', {
		detail: Object.assign({
			category: 'front-page-myft',
			action: 'view',
		}, getConceptCardTrackingInfo(concepts)),
		bubbles: true
	}));
};
