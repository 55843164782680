export default () => ({
	id: 'myft',
	title: '<a href="/myft" class="section-meta__link section-meta__link--myft-logo" data-trackable="section-title"><span class="section-meta__myft-icon">myFT</span></a>',
	sponsorshipInfo: {
		text: 'In association with',
		imageUrl: 'https://www.ft.com/__assets/creatives/third-party/ntt-communications-blue.png',
		alt: 'NTT Sponsorship',
		textClasses: 'myft-sponsor__text',
		imageClasses: 'myft-sponsor__image',
		width: '133',
		height: '43',
		clickthroughUrl: 'http://www.ntt.com/en/lp/glance?utm_source=other&utm_medium=display&utm_campaign=gbc_d_tp&utm_content=uk_ci__fti_003'
	},
	trackScrollEvent: true,
	// only the client should render this section (not the server)
	isClient: true
});
