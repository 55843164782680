import getTopics from './content-retrieval/fetch-topics';
import { json as fetchJson } from 'fetchres';

const dedupe = (concepts) => {
	return concepts.filter((concept, pos) => {
		const matchesConceptId = item => item.conceptId === concept.conceptId;
		return concepts.findIndex(matchesConceptId) === pos;
	});
};

const topicLimit = 20;

const fitToSlice = (arr) => {
	return arr.length > topicLimit ? arr.splice(0, topicLimit) : arr;
};

const swapUuidToConceptId = (topics) => topics.map(topic =>
	Object.assign({}, topic, {conceptId: topic.uuid}));


const fetchSimilarTopics = (userId) => {
	const proxyBaseUrl = 'https://www.ft.com/__myft';
	const similarTopicsPath = `/api/onsite/${userId}/history/similarity/topics`;

	return fetch(`${proxyBaseUrl}${similarTopicsPath}`)
		.then(fetchJson)
		.then((json = {}) => json)
		.catch(error => {
			console.error(error); //eslint-disable-line no-console
			return false;
		});
};

export const getTopicContent = async (flags, userId) => {
	try {
		const topics = await getTopics(flags, userId);

		const allConcepts = [...topics.historicConcepts, ...topics.popularConcepts];
		const dedupedConcepts = dedupe(allConcepts);
		return fitToSlice(dedupedConcepts);
	} catch (error) {
		console.error(error); //eslint-disable-line no-console
		return false;
	}
};

export const getSimilarTopicToReadingHistory = async (userId) => {
	try {
		const topics = await fetchSimilarTopics(userId);
		return (topics && topics.length) ? fitToSlice(swapUuidToConceptId(topics)) : false;
	} catch (error) {
		console.error(error); //eslint-disable-line no-console
		return false;
	}
};
