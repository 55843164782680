const fetchJson = require('fetchres').json;
const {readingHistory} = require('../queries');

const getSpoorId = () => {
	const cookies = document.cookie.split(';');
	let spoorId = cookies.find(cookie => cookie.includes('spoor-id'));
	return spoorId ? spoorId
		.trim()
		.replace(/^spoor-id=/, '') : spoorId;
};

module.exports = async function (flags, userId) {
	const spoorId = getSpoorId();
	const fetchOptions = {credentials: 'include', timeout: 5000};
	const variables = JSON.stringify({uuid: userId, spoorId});

	const readingHistoryPromise = (userId && spoorId) && fetch(
		`https://next-api.ft.com/v2/query?query=${readingHistory}&variables=${variables}&source=next-front-page-myft`,
		fetchOptions
	)
		.then(fetchJson)
		.then(({data: {user: {conceptsFromReadingHistory = []} = {}}} = {}) => conceptsFromReadingHistory);

	const popularPromise = fetch(
		'https://www.ft.com/__myft/collections/popular?limit=40',
		fetchOptions)
		.then(fetchJson);

	return Promise.all([readingHistoryPromise || [], popularPromise])
		.then(([conceptsFromReadingHistory, {popularConcepts}]) => ({conceptsFromReadingHistory, popularConcepts}))
		.then(({conceptsFromReadingHistory, popularConcepts}) => {
			popularConcepts.map(concept => {
				concept.conceptId = concept.id;
				delete concept.id;
			});

			conceptsFromReadingHistory.map(concept => {
				concept.conceptId = concept.id;
				concept.name = concept.prefLabel;
				delete concept.id;
			});

			return {
				popularConcepts: popularConcepts,
				historicConcepts: conceptsFromReadingHistory
			};
		});
};
