import { client as myFtClient, ui as myFtUi } from 'n-myft-ui/myft';
import * as myftInstantAlert from 'n-myft-ui/components/instant-alert';
import initPinButtons from 'n-myft-ui/components/pin-button';
import oDate from 'o-date';
import myftTemplate from '../../../views/partials/myft.html';
import topicTemplate from '../../../server/sections/myft/view-enhanced-topic.html';
import { getTopicContent, getSimilarTopicToReadingHistory } from './lib/get-topic-content';
import fetchPopularContent from './lib/content-retrieval/fetch-popular-content';
import fetchFeed from './lib/fetch-feed';
import getUuidFromSession from './lib/get-uuid-from-session';
import config from './config';

import trackConceptCardBehaviour from './lib/tracking/track-concept-card-behaviour';

const state = {};
let myFtContainerEl;
let userId;

const render = async (template, data = {}, flags) => {
	const model = Object.assign(data, {
		title: data.title || 'Visit myFT feed',
		url: '/myft',
		flags: flags.getAll(),
		followButtonVariant: 'inverse',
		userId
	});

	await myFtClient.init();
	const myFtHtml = template(model);
	myFtContainerEl.innerHTML = myFtHtml;
	myFtContainerEl.setAttribute('data-animate', 'fade-in');
	myFtUi.updateUi(myFtContainerEl);
	document.body.dispatchEvent(new CustomEvent('asyncContentLoaded'));
	myftInstantAlert.init({
		anonymous: !(/FTSession=/.test(document.cookie)),
		flags
	});
	if (flags.get('myftPrioritiseTopicsOnHomepage') && flags.get('myftPrioritiseTopics')) {
		initPinButtons();
	}
	oDate.init(myFtContainerEl);
};

const getUserContent = async (limit = 3) => {
	const userId = await getUuidFromSession();

	const concepts = userId ? await fetchFeed(userId, 'myft-section') : [];

	if (state.flags.get('myftPrioritiseTopicsOnHomepage')) {
		concepts.forEach(concept => {
			concept.isFollowing = concept.signals && concept.signals.followed;
		});
	}

	const conceptsToDisplay = [...concepts];

	if (concepts.length < limit) {
		const popularContent = userId ? await fetchPopularContent(state.flags, userId).catch(() => []) : [];
		const popularConcepts = Array.isArray(popularContent.concepts) ? popularContent.concepts : [];
		const otherConcepts = popularConcepts.filter(concept => !concepts.find(followedConcept => concept.id === followedConcept.id));
		conceptsToDisplay.push(...otherConcepts);
	}

	return {
		followsConcepts: concepts.filter(({signals: {followed} = {}}) => followed).length > 0,
		concepts: conceptsToDisplay.slice(0, limit)
	};
};

const toggleCtaButton = () => {
	const userHasFollowedEnough = myFtContainerEl.querySelectorAll('[data-myft-ui="follow"] [aria-pressed="true"]').length >= 1;
	const ctaContainer = myFtContainerEl.querySelector('[data-section-cta]');

	if (ctaContainer) {
		ctaContainer.classList.toggle('n-ui-hide', !userHasFollowedEnough);
	}
};

const refreshCards = async () => {
	const userContent = await getUserContent();
	return renderCards({concepts: userContent.concepts, flags: state.flags});
};

const triggerToggleOnFollow = () => {
	document.addEventListener('myft.user.followed.concept.add', toggleCtaButton);
	document.addEventListener('myft.user.followed.concept.remove', toggleCtaButton);
};

const refreshOnPrioritised = () => {
	document.addEventListener('myft.user.prioritised.concept.add', refreshCards);
	document.addEventListener('myft.user.prioritised.concept.remove', refreshCards);
};

const renderTopicSlice = async flags => {

	const topics = (flags.myftDataScienceRecommendations && userId) ?
		await getSimilarTopicToReadingHistory(userId) :
		await getTopicContent(flags, userId);

	if (topics) {
		return render(topicTemplate, { topics }, flags)
			.then(triggerToggleOnFollow);
	}
};

const renderCards = async ({ concepts, flags }) => {
	trackConceptCardBehaviour(concepts);
	const data = Object.assign({}, config(), { data: {content: concepts}});
	return render(myftTemplate, data, flags)
		.then(refreshOnPrioritised);
};

export default async flags => {
	state.flags = flags;
	userId = await getUuidFromSession();
	if (!flags.get('myFtApi') || !flags.get('frontPageMyFtSection')) {
		return;
	}

	try {
		myFtContainerEl = document.querySelector(`[data-section-container="myft-${flags.get('myFT_HigherHomePageSlice') ? 'high' : 'low'}"]`);
		const userContent = await getUserContent();
		if (userContent.followsConcepts) {
			return renderCards({concepts: userContent.concepts, flags});
		} else {
			return renderTopicSlice(flags);
		}
	} catch (err) {
		window.setTimeout(() => { throw err; });
	}
};
