const template = (data) => (`
	<a class="video-section__autoplay-up-next" data-trackable="manual" href="${data.url}">
		<i class="video-section__autoplay-up-next__icon"></i>
		<h2 class="video-section__autoplay-up-next__label">
			<span aria-hidden="true">Up Next</span>
			<span class="o-normalise-visually-hidden">Related videos</span>
		</h2>
		<p class="video-section__autoplay-up-next__heading">${data.title}</p>
		<span class="video-section__autoplay-up-next__duration">${data.duration}</span>
	</a>
`);

export default template;
