const template = (data) => (`
	<div data-o-component="o-share"
		class="o-share o-share--small o-share--inverse video-section__share"
		data-o-share-links="twitter facebook whatsapp linkedin link"
		data-o-share-url="${data.url}"
		data-o-share-title="${data.title}"
		data-o-share-titleExtra="Video">
	</div>
`);

export default template;
