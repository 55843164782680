const { fetchLegislation, buildConsentPageUrl } = require('@financial-times/privacy-legislation-client');
const { createCCPALink } = require('./create-ccpa-link');

const CONSENT_URL = 'https://www.ft.com/preferences/privacy-ccpa';

async function makeUiCcpaCompliant () {
	try {
		// Get a list of the applicable legislationIds for the user's region
		const { legislation } = await fetchLegislation();
		const consentPageUrl = buildConsentPageUrl({ url: CONSENT_URL, legislation });

		// If the user is in California update our UI to meet CCPA requirements
		if (legislation.has('ccpa')) {
			createCCPALink(consentPageUrl);
		}
	} catch (err) {
		console.error(err); //eslint-disable-line no-console
	}
};

module.exports = {
	makeUiCcpaCompliant
};
