'use strict';

const sectionsOnPage = [...document.querySelectorAll('section')];
const articlesOnPage = getArticleIds(document);

export const isArticleOnPage = articleId => articlesOnPage.includes(articleId);
export const getArticlesAboveSection = (section) => {
	if (!section) throw new Error('Section argument not supplied');
	if (!sectionsOnPage.includes(section)) throw new Error('Cannot find section in page');
	const articlesAboveSection = sectionsOnPage
		.slice(0, sectionsOnPage.indexOf(section)).map(node => getArticleIds(node))
		.reduce((acc, curr) => {
			acc.push(...curr);
			return acc;
		}, []);
	return new Set(articlesAboveSection);
};

function getArticleIds (element = document) {
	const ids = new Set();
	element.querySelectorAll('.o-teaser').forEach((el) => ids.add(el.dataset.id));
	return ids;
}
