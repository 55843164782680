import * as myftUi from 'n-myft-ui/myft';
import * as myftInstantAlert from 'n-myft-ui/components/instant-alert';
import OVideo from 'o-video';
import readyState from 'ready-state';
import marketsData from './components/markets-data/markets-data';
import unreadArticlesIndicator from 'n-myft-ui/components/unread-articles-indicator';
import regional from './components/regional';
import myft from './components/myft';
import nativeAd from './components/ads/native-ad';
import * as videoSection from './components/video';
import { nMessagingClient } from 'n-messaging-client';
import * as pageKitAppContext from '@financial-times/dotcom-ui-app-context';
import * as nTracking from '@financial-times/n-tracking';
import * as nFeedback from 'n-feedback';
import { displayAds, getAdsData, adsUtils } from '@financial-times/n-ads';
import * as nSyndication from 'n-syndication';
import oDate from 'o-date';
import { makeUiCcpaCompliant } from './components/privacy';

import LazyLoad from 'o-lazy-load';
import * as pageKitLayout from '@financial-times/dotcom-ui-layout';
import * as pageKitFlags from '@financial-times/dotcom-ui-flags';

readyState.domready.then(() => {
	const flags = pageKitFlags.init();
	const appContext = pageKitAppContext.init();

	pageKitLayout.init();

	if (flags.get('syndication')) {
		nSyndication.init(flags);
	}

	oDate.init();

	new LazyLoad(document.body);

	const clientOpts = [];

	if (flags.get('myFtApi')) {
		clientOpts.push({relationship: 'followed', type: 'concept'});
		clientOpts.push({relationship: 'saved', type: 'content'});
	}

	myftUi.client.init(clientOpts)
		.catch(e => {
			// Keeps console clean for anonymous users
			if (e !== 'No session cookie found') {
				throw e;
			}
		});

	marketsData(flags);

	if (flags.get('messageSlotBottom') || flags.get('messageSlotTop')) {
		nMessagingClient.init();
	}

	myftUi.ui.init({
		anonymous: !(/FTSession=/.test(document.cookie)),
		flags
	});

	myftInstantAlert.init({
		anonymous: !(/FTSession=/.test(document.cookie)),
		flags
	});

	if (flags.get('unreadArticlesIndicator')) {
		unreadArticlesIndicator({ flags: flags.getAll() });
	}

	regional(flags);

	myft(flags);

	if (flags.get('ads')) {
		const rootId = adsUtils.getRootID();
		const displayAdsOptions = {
			appName: appContext.get('appName'),
			abTestState: appContext.get('abTestState'),
			rootId,
			lazyLoadMargins: {
				760: '15%',
				980: '5%'
			},
		};

		if (flags.get('moatAdsTraffic')) {
			displayAds.validateTraffic();
		}

		// Fetch the ads data
		const adsDataPromise = getAdsData({ user: true });

		// Enable ads
		adsDataPromise.then(adsData => {
			displayAds.init({
				...displayAdsOptions,
				targeting: adsData.metadata,
				adUnit: ['home', appContext.get('edition') === 'uk' ? 'UK' : 'US']
			}, flags);
		}).catch(() => {
			console.warn('Failed to load targeted ads. Falling back to loading basic ads without targeting or ad unit'); //eslint-disable-line no-console
			displayAds.init(displayAdsOptions, flags);
		});

		// Enable permutive
		adsDataPromise.then(adsData => {
			if (flags.get('AdsPermutive')) {
				adsUtils.enablePermutiveFtCom({
					metadata: adsData.metadata,
					type: appContext.get('appName'),
					rootId
				});
			}
		}).catch(() => {
			console.warn('Failed to load permutive correctly. Falling back to loading permutive without page or user data'); //eslint-disable-line no-console
			adsUtils.enablePermutiveFtCom({
				type: appContext.get('appName'),
				rootId
			});
		});

		// Catch any errors from getAdsData()
		adsDataPromise.catch(e => {
			console.warn('There was an error fetching the ads data.', e); //eslint-disable-line no-console
		});
	}

	nativeAd(flags);

	videoSection.init(flags);

	OVideo.init();

	if (flags.get('oTracking')) {
		const oTracking = nTracking.init({ appContext: appContext.getAll() });

		oTracking.view.init({
			selector: '.js-track-scroll-event',
			category: 'page',
			action: 'scrolldepth'
		});

		oTracking.view.init({
			selector: '.o-teaser--audio',
			getContextData: (el) => {
				return {
					componentContentId: el.getAttribute('data-id'),
					component: 'teaser',
					type: 'audio',
					subtype: 'podcast' // only podcast is audio subtype at 03/2019. Need to change when audio has more subtypes.
				};
			}
		});
	}

	if (flags && flags.get('realUserMonitoringForPerformance')) {
		nTracking.trackers.realUserMonitoringForPerformance();
	}

	if (flags.get('qualtrics')) {
		// NOTE: n-feedback mutates the configuration object passed to it but
		// the app context object is frozen and immutable and must be cloned.
		nFeedback.init({ ...appContext.getAll() });
	}

	if (flags.get('privShowCCPALinkOnFooter')) {
		// Since this is meant to update the footer (for California users),
		// this should be one of the last bits of JS to run after the page has loaded
		makeUiCcpaCompliant();
	}
});
