const { get, track } = require('@financial-times/n-native-ads');

const { Teaser, presets } = require('@financial-times/x-teaser');
const { render } = require('@financial-times/x-engine');

const oDate = require('o-date').default;
const { broadcast } = require('n-ui-foundations');

const getNativeAd = get;
const trackNativeAd = track;

// Any formats allowed
const ALLOWABLE_FORMATS = [];
const skipSmartmatch = true;

function runNativeAds (oAds, el, usePinkBackground) {

	const pageUrl = 'ft.com';

	return getNativeAd(oAds, pageUrl, ALLOWABLE_FORMATS, skipSmartmatch)
		.then(response => {

			if(!response || !response.title) {
				return;
			}

			// Adds datatrackable value for content to the parent container
			const idNormalized = response.id ? '' + response.id : response.advertiser.split(' ').join('-').toLowerCase();
			el.dataset.trackable = `commercial-content | type-${response.type} | id-${idNormalized}`;

			response.widths = '[500, 332]';
			response.mods = [response.type, 'small'];

			const teaserData = Object.assign({}, response, presets.SmallHeavy, {
				modifiers: [response.type],
			});

			if (usePinkBackground) {
				teaserData.modifiers.push('ppost-pinkbg-test');
			}

			render(Teaser(teaserData), el);

			//untested from HERE
			[].concat(response.impressionURL).forEach(url => {
			//trigger all the impression tracking pixels
				const trackingPixelImage = document.createElement('IMG');
				trackingPixelImage.src = url;
			});
			//untested until HERE

			oDate.init(el);

			broadcast('oTracking.event', {
				action: 'native-render',
				category: 'ads',
				native_ad_type: response.type,
				native_ad_id: idNormalized
			});

			trackNativeAd(el.firstElementChild, (viewstate) => {
				broadcast('oTracking.event', {
					action: `native-viewed-${viewstate}`,
					category: 'ads',
					native_ad_type: response.type,
					native_ad_id: idNormalized
				});
			});
		});
};

module.exports = (flags) => {

	if (flags.get('nativeAds')) {

		const el = document.querySelector('.native-ad-container');

		if (!el) {
			return;
		}

		const oAds = window.oAds;
		const usePinkBackground = flags.get('adsPaidPostPinkBg');

		if (oAds && oAds.isInitialised) {
			return runNativeAds(oAds, el, usePinkBackground);
		} else {
			document.addEventListener('oAds.initialised', e => {
				return runNativeAds(e.detail, el, usePinkBackground);
			});
		}
	}
};
