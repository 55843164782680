const fetchJson = require('fetchres').json;
const transforms = require('../transforms');

const pickConcepts = (data) => {
	data.popularConcepts.forEach(concept => concept.contentSource = 'popular');

	const sortedPopularConcepts = data.popularConcepts.sort(sortConcepts);
	const concepts = sortedPopularConcepts
		.filter(transforms.removeEmptyConcepts)
		.filter(transforms.removeDuplicateConcepts);

	concepts.reduce(transforms.filterDuplicateArticles, []);

	return {
		concepts
	};
};

const doesUserFollowConcepts = (data) => {
	data.followsConcepts = Boolean(data.followed && data.followed.length);
	return data;
};

const sortConcepts = (a, b) => {
	const latestA = a.latestContent[0] && a.latestContent[0].publishedDate || 0;
	const latestB = b.latestContent[0] && b.latestContent[0].publishedDate || 0;
	const dateA = new Date(latestA).getTime();
	const dateB = new Date(latestB).getTime();
	return dateB - dateA;
};

module.exports = async function () {
	const fetchOptions = {
		credentials: 'include',
		timeout: 5000
	};
	return fetch('https://www.ft.com/__myft/collections/popular?limit=6&latestContent', fetchOptions)
		.then(fetchJson)
		.then(doesUserFollowConcepts)
		.then(pickConcepts);

};
