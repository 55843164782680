const myftClient = require('next-myft-client').default;

const fetchPrioritised = async userId => {
	const response = await myftClient.fetchJson('GET', `${userId}/prioritised/concept`);
	return response && response.items.length ? response.items : [];
};

const fetchPersonalisedFeed = async (userId, featureName) => {
	const searchParams = new URLSearchParams({
		originatingSignals: ['followed', 'topicReadingHistory'],
		grouped: 'concept',
		dedupeArticles: 'basic',
		maxConcepts: '3',
		maxArticlesPerConcept: '5',
		source: `next-front-page${featureName ? `:${featureName}` : ''}`
	});
	const response = await myftClient.fetchJson('GET', `feed/${userId}?${searchParams.toString()}`);
	if (response && response.concepts) {
		return response.concepts
			.map(concept => {
				concept.latestContent = concept.items
					.slice(0, 5)
					.map(item => Object.assign({}, item, item.content));
				return concept;
			});
	} else {
		return [];
	}
};

const enrichConceptsWithPrioritisedMetaData = (concepts, prioritisedConcepts) => {
	if (!prioritisedConcepts) {
		return concepts;
	}

	return concepts
		.map(concept => {
			const match = prioritisedConcepts.find(prioritisedConcept => prioritisedConcept.uuid === concept.id);

			if (match) {
				concept.prioritised = true;
				concept.prioritisedOn = match['_rel'].created;
			}

			return concept;
		});
};


const splitPrioritisedAndUnprioritised = (items = []) => {
	const unprioritised = items.filter(item => !item.prioritised);
	const prioritised = items.filter(item => item.prioritised);

	return {prioritised, unprioritised};
};

const sortByPriortisedOn = prioritised => {
	return prioritised.sort((a, b) => {
		return b.prioritisedOn - a.prioritisedOn;
	});
};

module.exports = async (userId, featureName) => {
	const concepts = await fetchPersonalisedFeed(userId, featureName);

	if (concepts) {
		const prioritisedConcepts = await fetchPrioritised(userId);
		const prioritisedUserContent = enrichConceptsWithPrioritisedMetaData(concepts, prioritisedConcepts);
		const {prioritised, unprioritised} = splitPrioritisedAndUnprioritised(prioritisedUserContent);
		return [].concat(sortByPriortisedOn(prioritised), unprioritised);
	}

	return [];
};
