const getDistinctSources = concepts => {
	return Array.from(new Set(concepts
		.filter(concept => !!concept.contentSource)
		.map(concept => concept.contentSource)
	));
};

const getContentSourceCounts = concepts => {
	return Object.assign({},
		...getDistinctSources(concepts).map(source => (
			{
				[source]: concepts
					.filter(concept => concept.contentSource === source)
					.reduce((sum, concept) => sum + concept.items.length, 0)
			}
		)));
};

const getContentSources = concepts => {
	const sources = getDistinctSources(concepts);
	if (!sources.length) {
		return null;
	} else if (sources.length === 1) {
		return `${sources[0]}_only`;
	} else {
		return sources.join('_and_');
	}
};

const getOldFollowContentShown = concepts => {
	return concepts
		.filter(concept => concept.contentSource === 'follows')
		.map(concept => concept.items.filter(item => item.isOldContent).length)
		.reduce((total, val) => total + val, 0);
};

module.exports = concepts => {
	return {
		contentSourceCounts: getContentSourceCounts(concepts),
		contentSources: getContentSources(concepts),
		oldFollowContentShown: getOldFollowContentShown(concepts)
	};
};
