import { render, h } from '@financial-times/x-engine';
import { RegionalTemplate } from '../../../views/partials/regional-client.jsx';
import getUuidFromSession from '../myft/lib/get-uuid-from-session';
import {getArticlesAboveSection} from '../../utils/dedupe';
import {broadcast} from 'n-ui-foundations';
import {json as fetchJson} from 'fetchres';

const containerEl = document.querySelector('[data-section-container="regional-news"]');

function fetchRegionalFeed (countryCode) {
	const fetchOptions = {
		credentials: 'include',
		timeout: 5000
	};
	const searchParams = new URLSearchParams({
		format: 'teaser',
		source: 'next-front-page:regional-news-section'
	});
	const articlesAboveSection = getArticlesAboveSection(containerEl.querySelector('section'));
	return fetch(`/__myft/api/onsite/country-feed/${countryCode}?${searchParams.toString()}`, fetchOptions)
		.then(fetchJson)
		.then(response => {
			response.items = response.items
				.filter(({id}) => !articlesAboveSection.has(id));
			return response;
		});
}

function getUsersCountryCode (userId) {
	const overriddenCountryCode = new URLSearchParams(window.location.search).get('countryCode');

	if (overriddenCountryCode && /([A-Z]{2,3})/.test(overriddenCountryCode)) {
		return Promise.resolve(overriddenCountryCode);
	}

	if (!userId) return 'GBR'; // fallback

	const fetchOptions = {
		credentials: 'include',
		timeout: 5000
	};
	return fetch(`/__myft/users/${userId}/country`, fetchOptions)
		.then(fetchJson)
		.then(({countryCode}) => countryCode);
}

const renderer = async (containerEl, JSXComponent, data, flags) => {
	if( !data || !data.items ) { return; }

	const model = {
		flags,
		items: data.items,
		storyCount: 4,
		show: true,
		title: `${data.title} News`,
		url: data.url
	};

	render(h(JSXComponent, model), containerEl, containerEl.querySelector('section'));

	broadcast('oTracking.event', {
		category: 'front-page-myft',
		action: 'regional-news-mounted',
		detail: {
			country: data.title,
		}
	});
};

export default async flags => {

	if( !containerEl ) {
		return;
	}

	try {
		const userId = await getUuidFromSession();
		const countryCode = await getUsersCountryCode(userId);
		if((countryCode !== 'GBR' && countryCode !== 'USA') ) {
			const regionalContent = await fetchRegionalFeed(countryCode);
			if (regionalContent.items && regionalContent.items.length >= 4) {
				return renderer(containerEl, RegionalTemplate, regionalContent, flags);
			}
		}
	} catch (err) {
		window.setTimeout(() => {
			throw err;
		});
	}
};
