var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../../../bower_components/n-myft-ui/components/follow-button/follow-button.html"),depth0,{"name":"n-myft-ui/components/follow-button/follow-button","hash":{"variant":(depths[1] != null ? lookupProperty(depths[1],"followButtonVariant") : depths[1]),"buttonText":(depth0 != null ? lookupProperty(depth0,"name") : depth0)},"data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"myft-onboarding js-track-scroll-event\" data-trackable=\"myft-topics\">\n"
    + ((stack1 = container.invokePartial(require("../../../views/partials/teaser-collections/heading.html"),depth0,{"name":"../../../views/partials/teaser-collections/heading","hash":{"headingTag":"h2"},"data":data,"indent":"\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "	<p class=\"myft-onboarding__description\">Add topics to myFT to create a personalised news feed of your essential stories. Start now</p>\n	<div class=\"o-grid-row\">\n		<div data-o-grid-colspan=\"12\">\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? lookupProperty(depth0,"topics") : depth0), depth0),{"name":"topics","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":3},"end":{"line":8,"column":14}}})) != null ? stack1 : "")
    + "		</div>\n		<div class=\"myft-onboarding__cta-container n-ui-hide\" data-o-grid-colspan=\"12\" data-section-cta>\n			<a href=\"/myft/following\" class=\"n-myft-onboarding-cta\" data-trackable=\"cta\">\n				See stories\n			</a>\n		</div>\n	</div>\n</section>\n";
},"usePartial":true,"useData":true,"useDepths":true});