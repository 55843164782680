const followedConcept = (article, concept = {}) => {
	const followedConcept = Object.assign({}, concept);
	delete followedConcept.latestContent;
	article.followedConcept = followedConcept;

	return article;
};

const removeEmptyConcepts = concept => Boolean(concept.latestContent && concept.latestContent.length);

const removeDuplicateConcepts = (concept, index, allConcepts) =>
	allConcepts.map(allConcept => allConcept.id).indexOf(concept.id) === index;

const filterDuplicateArticles = (articles, concept) => {
	concept.items = concept.latestContent
		.filter(item => !articles.find(article => article.id === item.id));
	return articles.concat(concept.items);
};

module.exports = {
	followedConcept,
	removeEmptyConcepts,
	removeDuplicateConcepts,
	filterDuplicateArticles
};
