import { h } from '@financial-times/x-engine';
import { Teaser, presets } from '@financial-times/x-teaser';

const Heading = (props) => {
	return (
		<h2 className="o-teaser-collection__heading o-teaser-collection__heading--half-width">
			{props.url ? <a href={props.url} data-trackable="section-title">{props.title}</a> : props.title}
		</h2>
	);
};

const Template = ({items}) => {
	const slicedItems = items.slice(1,4);
	return (
		<div className="o-grid-row">
			<div data-o-grid-colspan="12 L6">
				<Teaser {...items[0]} {...presets.Large}/>
			</div>
			<div data-o-grid-colspan="12 L6">
				{slicedItems.map(item => {
					return <Teaser {...item} {...presets.Small} showStandfirst={true} />;
				})}
			</div>
		</div>
	);};

export const RegionalTemplate = (props) => {
	return(
		<section className="o-teaser-collection js-track-scroll-event" data-trackable="regional-top-stories">
			<Heading {...props}/>
			<Template items={props.items}/>
		</section>
	);};
